// imports
@import "globals";
@import "spinner";
@import "bootstrap/bootstrap-reboot";
@import "bootstrap/bootstrap-grid";
@import "bootstrap/variables";
@import "bootstrap/utilities";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/dropdown";
@import "bootstrap/card";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/media";
@import "bootstrap/badge";
@import "bootstrap/modal";
@import "bootstrap/input-group";
@import "bootstrap/forms";
@import "bootstrap/custom-forms";

// variables
$darkblue: #163650;
$theme-orange: rgba(245, 131, 46, 1);
$theme-blue: rgba(32, 156, 241, 1);
$theme-green: rgba(94, 193, 125, 1);
$theme-purple: rgba(186, 130, 230, 1);
$theme-dirtyorange: rgba(232, 168, 6, 1);
$theme-white: rgba(216, 85, 85, 1);
$theme-dark-blue: rgba(22, 54, 80, 1);
$theme-white: #000000;

// fonts
@font-face {
  font-family:"Eina-Bold";
  src: url("/fonts/Eina02-Bold.ttf") format("truetype");
}

@font-face {
  font-family:"Eina-BoldItalic";
  src: url("/fonts/Eina02-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family:"Eina-Light";
  src: url("/fonts/Eina02-Light.ttf") format("truetype");
}

@font-face {
  font-family:"Eina-LightItalic";
  src: url("/fonts/Eina02-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family:"Eina-Regular";
  src: url("/fonts/Eina02-Regular.ttf") format("truetype");
}

@font-face {
  font-family:"Eina-RegularItalic";
  src: url("/fonts/Eina02-RegularItalic.ttf") format("truetype");
}

@font-face {
  font-family:"Eina-SemiBold";
  src: url("/fonts/Eina02-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family:"Eina-SemiBoldItalic";
  src: url("/fonts/Eina02-SemiBoldItalic.ttf") format("truetype");
}

.custom-gutter {
  margin-left: -40px;
  margin-right: -40px;
}
.custom-gutter > [class*='col'] {
    padding-right: 40px;
    padding-left: 40px;
}

.custom-gutter > [class*="col"]:first-child {
  padding-left: 40px;
}

.custom-gutter > [class*="col"]:last-child {
  padding-right: 40px;
}

.eina-bold {
  font-family: Eina-Bold;
}

.eina-regular {
  font-family: Eina-Regular;
}

.eina-light {
  font-family: Eina-Light;
}

.download-button {
  &:hover {
    opacity: 0.8;
  }
}

// css
body {
  h1, h2 {
    font-family: Eina-Bold;
    color: $theme-dark-blue;
  }
  h3, h4, h5 {
    font-family: Eina-SemiBold;
    color: $theme-dark-blue;
  }
  a {
    font-family: Eina-Regular;
    // color: black !important;
    // text-decoration: none !important;
    &:hover {
      color: $theme-blue;
      // text-decoration: none;
    }
  }
  p, span {
    font-family: Eina-Regular;
    strong {
      font-family: Eina-SemiBold;
    }
  }
  .btn-primary {
    background: $theme-blue;
    color: white !important;
  }
  .btn-link {
    color: $theme-blue!important;
  }
  .display-4 {
    font-size: 3em;
  }
}

.Navbar {
  ul {
    li {
      a{
        color: $darkblue;
      }
    }
  }
  .Navbar__toggler {
    border: none;
    outline: none;
  }
  .Navbar__navitem {
    > a {
    font-family: Eina-SemiBold;
    color: $theme-dark-blue !important;
    }
  }
  .Navbar__navitem--hover {
    border-radius: 0.9rem 0.9rem 0.9rem 0.9rem;
    background: #FCFCFC;
  }
  .Navbar__dropdowntoggle {
    &:after {
      border: none;
      margin-left: 0px;
    }
  }
  .Navbar__dropdown--clicked {
    background: white !important;
    border-radius: 0.9rem 0.9rem 0rem 0rem !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  .Navbar__dropdown-menu {
    border-radius: 0rem 0.9rem 0.9rem 0.9rem;
  }
  .Navbar__dropdown-menu--clicked {
    border-radius: 0rem 0.9rem 0.9rem 0.9rem;
    box-shadow: 0 -0.5rem white, 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  .show > .navbar-nav {
    .Navbar__dropdown--clicked {
      box-shadow: none !important;
    }
    .Navbar__dropdown-menu--clicked {
      box-shadow: none !important;
    }
  }
  .Navbar__dropdownitem {
    &:hover {
      font-family: Eina-Regular;
      color: #3FA9F5;
    }
    &:active {
      background-color: #f8f9fa;
    }
  }
}

.Footer {
  background-color: black;
  .Footer__link {
    color: RGB(63,169,245,1);
  }
}

.MainHero {
  .MainHero__image {
  }
  .MainHero__inner {
    .MainHero__bgpanel {
      width: 65%;
      height: 96%;
      max-height: 770px;
      position: absolute;
      top:0; right:0;
      z-index:-10;
      background: #163650;
      opacity: 0.05;
      box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.25);
      border-radius: 0 0 0 5%;
      @media (max-width: 575.98px) {
        height: 94%;
      }
      // Small devices (landscape phones, less than 768px)
      @media (max-width: 767.98px) {
        height: 94%;
      }
      // Medium devices (tablets, less than 992px)
      @media (max-width: 991.98px) {
        height: 94%;
      }
      // Large devices (desktops, less than 1200px)
      @media (max-width: 1199.98px) {
        height: 94%;
        max-height: 700px;
      }
      @media (min-width: 1199.98px) {
        height: 94%;
      }
    }
    .MainHero__headline {

    }
    .MainHero__statscard {
      background: #FFFFFF;
      box-shadow: 0px 25px 85px rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      top: -6em;
      @media (max-width: 575.98px) {
        top: -5em;
      }
      // Small devices (landscape phones, less than 768px)
      @media (max-width: 767.98px) {
        top: -5em;
      }
      // Medium devices (tablets, less than 992px)
      @media (max-width: 991.98px) {
        top: -5em;
      }
      // Large devices (desktops, less than 1200px)
      @media (max-width: 1199.98px) {
        top: -5em;
      }
      .MainHero__statscarditem {
        position: relative;
        // border-right: 1px solid rgba(22, 54, 80, 0.2);
        // &:last-child {
        //   border-right: none;
        // }
      }
      .MainHero__decoration {
        width: 6em;
        position: absolute;
        left: -2em;
        bottom: -2em;
        z-index: -10;
      }
      .MainHero__statscardheader {
        font-weight: bold;
        color: #30A4E3;
        font-size: 1.5em;
        padding: 0px;
        margin: 0px;
      }
      .MainHero__statscardsubheader{
        font-family: Eina-SemiBold;
        font-size: 1em;
        padding: 0px;
        margin: 0px;
        color: $theme-dark-blue;
      }
    }
  }
}

.Phoner {
  position: relative;
  .Phoner__inner {
    position: relative;
    .Phoner__bgpanel {
      width: 50%;
      height: 80%;
      position: absolute;
      bottom: 0; left:0;
      z-index:-10;
      background: #163650;
      opacity: 0.05;
      box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.25);
      border-radius: 0 55px 55px 0;
    }
    .Phoner__description {
      ul li {
        color: $theme-blue;
        padding-top: 5px;
        padding-bottom: 5px;
      }
      ul li span {
        color: black;
      }
    }
    .Phoner__buttons {
      .Phoner__buttonsbutton {
        height:44px;
        width:130px;
        line-height:35px;
        background-color: $theme-blue;
        color: white;
      }
    }
  }
}

.Vpn {
  position: relative;
  .Vpn__inner {
    position: relative;
    .Vpn__bgpanel {
      width: 50%;
      height: 80%;
      position: absolute;
      bottom: 0;
      right:0;
      z-index:-10;
      background: #163650;
      opacity: 0.05;
      box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.25);
      border-radius: 55px 0 0 55px;
    }
    .Vpn__description {
      ul li {
        color: $theme-blue;
        padding-top: 5px;
        padding-bottom: 5px;
      }
      ul li span {
        color: black;
      }
    }
    .Vpn__buttons {
      .Vpn__buttonsbutton {
        height:44px;
        line-height:35px;
        background-color: $theme-blue;
        color: white;
      }
    }
  }
}

.Products {
  position: relative;
  .Products__inner {
    position: relative;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.07);
    .Products__decoration {
      width: 8em;
      position: absolute;
      left: -2.28em;
      top: -2.18em;
      z-index: -10;
    }
    .Products__item {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    }
    .Products__logo {
      border-radius: 50%;
      height: 5em;
      box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.1);
    }
    .Products__description {
      // min-height: 40%;
    }
    .Products__buttons {
      a {
        position: relative;
      }
    }
    .Products__buttons--foripad {
      position: relative;
      padding-bottom: 1.5em;
      &:before {
        content: "For iPad";
        position: absolute;
        top: -1.5em;
      }
    }
    .Products__buttons--foriphone {
      position: relative;
      padding-bottom: 1.5em;
      &:before {
        content: "For iPhone";
        position: absolute;
        top: -1.5em;
      }
    }
    .btn {
      height:44px;
      width:130px;
      line-height:35px;
    }
    a {
      color: black;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.Intermission {
  position: relative;
  .Intermission__inner {
    position: relative;
    .Intermission__bgpanel {
      width: 75%;
      height: 140%;
      position: absolute;
      top: -40%;
      z-index:-10;
      background: #163650;
      opacity: 0.05;
      box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.25);
      border-radius: 0 55px 55px 0;
    }
    .Intermission__decoration {
      width: 8em;
      position: absolute;
      left: 18%;
      bottom: 0%;
      z-index: 10;
    }
    .Intermission__button {
      background: $theme-blue;
      color: white;
    }
    .Intermission--content {
      top: 0em;
      @media (max-width: 575.98px) {
        top: 0em;
      }
      // Small devices (landscape phones, less than 768px)
      @media (max-width: 767.98px) {
        top: 0em;
      }
      // Medium devices (tablets, less than 992px)
      @media (max-width: 991.98px) {
        top: 0em;
      }
      // Large devices (desktops, less than 1200px)
      @media (max-width: 1199.98px) {
      }
    }
  }
}

.Testimonial {
  .Testimonial__inner {

  }
}

.About {
  .About__bgpanel {
    width: 70%;
    height: 70%;
    position: absolute;
    top: 0;
    left:0;
    z-index:-10;
    background: #163650;
    opacity: 0.05;
    box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.25);
    border-radius: 55px 0 0 55px;
  }
  .About__header {

  }
  .About__headerbg {
    background: $theme-dark-blue;
  }
  .About__profiletitle {
    color: #3FA9F5;
    font-family: Eina-Bold;
  }
  .About__profilepicture {
  }
}

.AboutUs {
  .AboutUsHero {

  }
  .AboutUsDescription {
    position: relative;
    color: white;
    .AboutUsDescription__bg {
      background: $theme-dark-blue;
      height: 90%;
      width :100%;
      position: absolute;
      bottom: 0;
    }
    .AboutUsDescription__decoration {
      position: absolute;
      top: -10%;
      right: -10%;
      z-index: -10;
    }
    .AboutUsDescription__stats {
      background: $theme-dark-blue;
      position: relative;
      top: -30%;
    }
    .AboutUsDescription__statstitle {
      color: rgba(63, 169, 245, 1);
    }
  }
  .AboutUsStory {
  }
  .AboutUsValues {
    position: relative;
    color: white;
    .AboutUsValues__bg {
      background: #163650;
      height: 100%;
      width: 100%;
      position: absolute;
      // border-radius: 0px 25px 25px 0px;
      // @media (max-width: 575.98px) {
      //   width: 100%;
      // }
      // // Small devices (landscape phones, less than 768px)
      // @media (max-width: 767.98px) {
      //   width: 95%;
      // }
      // // Medium devices (tablets, less than 992px)
      // @media (max-width: 991.98px) {
      //   width: 95%;
      // }
      // // Large devices (desktops, less than 1200px)
      // @media (max-width: 1199.98px) {
      //   width: 95%;
      // }
    }
    .AboutUsValues__title {
      color: rgba(63, 169, 245, 1);
    }
    .AboutUsValues__card {
      color: black;
      border-radius: 10px;
      border: 0px;
      box-shadow: 0 11px 20px 0 rgba(0,0,0,.15);
    }
  }
  .AboutUsAffiliation {
    background: white;
    .AboutUsAffiliation__card {
            background: #f9fafb;
      border: none;
      border-radius: 15px;
      transition: all .2s ease;
      &:hover {
        box-shadow: 0 11px 20px 0 rgba(0,0,0,.15);
      }
    }
  }
}

.Phoner {
  hr {
    width: 62px;
    border: 3px solid #30A4E3 !important;
    border-radius: 5px !important;
  }
  .PhonerHero {
    color: white;
    position: relative;
    .PhonerHero__bgpanel {
      background: $theme-dark-blue;
      position: absolute;
      top: 10%;
      height: 80%;
      z-index: -10;
      // Extra small devices (portrait phones, less than 576px)
      @media (max-width: 575.98px) {
        top: 0%;
        height: 100%;
      }

      // Small devices (landscape phones, less than 768px)
      @media (max-width: 767.98px) {
        top: 0%;
        height: 100%;
      }

      // Medium devices (tablets, less than 992px)
      @media (max-width: 991.98px) {
        top: 0%;
        height: 100%;
      }

      // Large devices (desktops, less than 1200px)
      @media (max-width: 1199.98px) {

      }
    }
    .PhonerHero__right {
    }
    .PhonerHero__image {
    }
    .PhonerHero__headline {
      h1 {
        color: $theme-orange;
      }
      h3 {
        color: white;
      }
      &:hover {
        text-decoration: none;
      }
      &:active {
        text-decoration: none;
      }
    }
  }


  .PhonerSubheadline {

  }
  .PhonerStats {
    z-index: 9999;
    .PhonerStats__card {
      position: relative;
      box-shadow: 0px 25px 85px rgba(0, 0, 0, 0.07);
      border-radius: 4px;
      .PhonerStats__cardinner {
      }
      .PhonerStats__carditem {
        position: relative;
        border-right: 1px solid rgba(22, 54, 80, 0.2);
        &:last-child {
          border-right: none;
        }
        .PhonerStats__carditemheader {
          color: $theme-orange;
        }
      }
      .PhonerStats__carddecoration {
        position: absolute;
        bottom: -50px;
        right: -50px;
        z-index: -9999;
      }
    }
  }

  .PhonerFeatures {
    .PhonerFeatures__titledecoration {
      border-left: 3px solid $theme-blue;
    }
    .PhonerFeatures__icon {
      height: 4em;
    }
  }

  .PhonerWhyUse {
    .PhonerWhyUse__titledecoration {
      border-left: 3px solid $theme-blue;
    }
    .PhonerWhyUse__reasons {
      position: relative;
      .PhonerWhyUse__reasonsbg {
        position:absolute;
        height: 84%;
        width: 100%;
        top: 8%;
        background: RGB(247,249,249);
        // Extra small devices (portrait phones, less than 576px)
        @media (max-width: 575.98px) {
          top: 5%;
          height: 90%;
        }

        // Small devices (landscape phones, less than 768px)
        @media (max-width: 767.98px) {
          top: 5%;
          height: 90%;
        }

        // Medium devices (tablets, less than 992px)
        @media (max-width: 991.98px) {
          top: 5%;
          height: 90%;
        }

        // Large devices (desktops, less than 1200px)
        @media (max-width: 1199.98px) {

        }



      }
    }
    .PhonerWhyUse__card {
      box-shadow: 0px 8px 32px rgba(15, 35, 54, 0.06);
      border-radius: 10px;
    }
  }
  .PhonerCta1 {
    .PhonerCta1__inner {
      background: $theme-dark-blue;
    }
  }
  .PhonerCta2 {
    background: $theme-dark-blue;
  }
  .PhonerUseCase {
    .PhonerUseCase_listitem {
      min-height: 4em;
      box-shadow: 0px 4px 10px 0px rgba(15, 35, 54, 0.1);
      border-radius: 8px;
      max-width: 43%;
      &:nth-child(3) {
        margin-left: 8.3333333333% !important;
      }
    }
    .PhonerUseCase_convo {
      position: relative;
      .PhonerUseCase_convobg {
        position: absolute;
        top: 10%;
        right: 0px;
        height: 90%;
        width: 85%;
        background: #F7F9F9;
        border-radius: 49px;
      }
      .PhonerUseCase_convoitem {
        border-radius: 20px;
        padding: 4%;
        margin-bottom: 5%;
        &:last-child {
          margin-bottom: 0;
        }
        &.PhonerUseCase_convoitem--primary {
          background: white;
          box-shadow: 0px 10px 20px 0px rgba(15, 35, 54, 0.1);
        }
        &.PhonerUseCase_convoitem--secondary1 {
          background: $theme-orange;
          color: white;
          box-shadow: 0px 10px 20px 0px rgba(254, 106, 59, 0.2);
        }
        &.PhonerUseCase_convoitem--secondary2 {
          background: $theme-blue;
          color: white;
          box-shadow: 0px 10px 20px 0px rgba(32, 156, 241, 0.2);
        }
        &.PhonerUseCase_convoitem--secondary3 {
          background: $theme-purple;
          color: white;
          box-shadow: 0px 10px 20px 0px rgba(186, 130, 230, 0.2);
        }
      }
    }

  }
  .PhonerTestimonial {

  }
  .PhonerCta2 {

  }
}

.CallRec {
  .CallRec__hero {
    background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  }
  .CallRec__features {
    background-color: #fff;
  }
  .CallRec__whyuse {
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  }
  .CallRec__ctaone {
    background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  }
  .CallRec__usecases {
    background-color: #fff;
  }
  .CallRec__ratings {
    background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  }
  .CallRec__reviews {
    background-color: #dadada;
  }
  .CallRec__faqs {
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  }
  .CallRec__ctatwo {
    background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  }
}
.Scanner {
  .Scanner__hero {
    // background: #1E2022;  /* fallback for old browsers */
    // background: -webkit-linear-gradient(to bottom, #414345, #1E2022);  /* Chrome 10-25, Safari 5.1-6 */
    // background: linear-gradient(to bottom, #414345, #1E2022); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    // color: white;
    background-color: #ED863A;
  }
  .Scanner__features {
    background-color: #fff;
  }
  .Scanner__whyuse {
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  }
  .Scanner__ctaone {
    // background: #1E2022;  /* fallback for old browsers */
    // background: -webkit-linear-gradient(to bottom, #414345, #1E2022);  /* Chrome 10-25, Safari 5.1-6 */
    // background: linear-gradient(to bottom, #414345, #1E2022); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    // color: white;
    background-color: #ED863A;
  }
  .Scanner__usecases {
    background-color: #fff;
  }
  .Scanner__ratings {
    // background: #1E2022;  /* fallback for old browsers */
    // background: -webkit-linear-gradient(to bottom, #414345, #1E2022);  /* Chrome 10-25, Safari 5.1-6 */
    // background: linear-gradient(to bottom, #414345, #1E2022); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    // color: white;
    background-color: #ED863A;
  }
  .Scanner__reviews {
    background-color: #dadada;
  }
  .Scanner__faqs {
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  }
  .Scanner__ctatwo {
    // background: #1E2022;  /* fallback for old browsers */
    // background: -webkit-linear-gradient(to bottom, #414345, #1E2022);  /* Chrome 10-25, Safari 5.1-6 */
    // background: linear-gradient(to bottom, #414345, #1E2022); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    // color: white;
    background-color: #ED863A;
  }
}

.Fax {
  .Fax__hero {
    background-image: linear-gradient(to bottom, #09203f 70%, #1E2022 100%);
    color: white;
  }
  .Fax__features {
    background-color: #fff;
  }
  .Fax__whyuse {
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  }
  .Fax__ctaone {
    background-image: linear-gradient(to bottom, #09203f 70%, #1E2022 100%);
    color: white;
  }
  .Fax__usecases {
    background-color: #fff;
  }
  .Fax__ratings {
    background-image: linear-gradient(to bottom, #09203f 70%, #1E2022 100%);
    color: white;
  }
  .Fax__reviews {
    background-color: #dadada;
  }
  .Fax__faqs {
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  }
  .Fax__ctatwo {
    background-image: linear-gradient(to bottom, #09203f 70%, #1E2022 100%);
    color: white;
  }
  .Fax__buttonsendfax {
    background: rgb(0, 159, 218);
    font-family: Eina-Bold;
    width: 100%;
    border-radius: 30px;
    &:hover {
      opacity: 0.8;
    }
  }
  .Fax__buttongetnumber {
    background: rgb(151, 61, 228) !important;
    font-family: Eina-Bold;
    width: 100%;
    border-radius: 30px;
    &:hover {
      opacity: 0.8;
    }
  }
  .Fax__buttondownloadios {
    background: #B81212 !important;
    font-family: Eina-Bold;
    width: 100%;
    border-radius: 30px;
    &:hover {
      opacity: 0.8;
    }
  }
}

.Vpn {
  .Vpn__hero {
    position: relative;
    &:after {
      background-image: url("../images/vpn/vpn-bg.jpeg"), linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
      background-size: cover;
      background-repeat: no-repeat;
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
      opacity: 0.3;
    }
  }
  .Vpn__intro {}
  .Vpn__features {}
  .Vpn__ctaone {
    position: relative;
    &:after {
      background: #EFEFEF;
      background-size: cover;
      background-repeat: no-repeat;
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
      opacity: 0.3;
    }
  }
  .Vpn__whyuse {
    li {
      text-decoration: none;
    }
  }
  .Vpn__affirmation {}
  .Vpn__ctatwo {
    position: relative;
    &:after {
      background-image: url("../images/vpn/vpn-bg.jpeg"), linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
      background-size: cover;
      background-repeat: no-repeat;
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
      opacity: 0.3;
    }
  }
}

.Influencers__apply {
  font-size: 1.2em;
  font-weight: bold;
  color: white;
}
.Influencers__howTo {
  position: relative;
  .Influencers__bgpanel {
    width: 50%;
    height: 100%;
    max-height: 770px;
    position: absolute;
    top:0; left:0;
    z-index:-10;
    background: rgba(63, 169, 245, 1);
    opacity: 0.05;
    border-radius: 0 115px 115px 0;
    @media (max-width: 575.98px) {
      height: 94%;
    }
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 767.98px) {
      height: 94%;
    }
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991.98px) {
      height: 94%;
    }
    // Large devices (desktops, less than 1200px)
    @media (max-width: 1199.98px) {
      height: 94%;
      max-height: 700px;
    }
    @media (min-width: 1199.98px) {
      height: 94%;
    }
  }
  .Influencers__howToCard {
    background: #FFFFFF;
    box-shadow: 0px 25px 85px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }
}
